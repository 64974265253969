<template>
  <LargeLayout>
    <div class="container">
      <div class="row body">
        <div class="col-12 register">
          <form-wizard
            ref="form"
            steps-classes="wizardSteps"
            shape="circle"
            subtitle=""
            :title="title"
            @on-change="changeTitle"
          >
            <tab-content :title="$t('register.new')">
              <Step1 @next_step_1="NextStep1" :error="error_step_1"></Step1>
            </tab-content>
            <tab-content :title="$t('register.authentication')">
              <Step2
                @next_step_2="NextStep2"
                :email="data.email"
                :error="error_step_2"
              ></Step2>
            </tab-content>
            <tab-content :title="$t('register.enter info')">
              <Step3
                @next_step_3="NextStep3"
                :email="data.email"
                :error="error_step_3"
              ></Step3>
            </tab-content>
            <tab-content :title="$t('register.confirm')">
              <Step4
                @prev-step-4="PrevStep4"
                @next-step-4="NextStep4"
                :email="data.email"
                :password="data.password"
                :family_name="data.family_name"
                :given_name="data.given_name"
              ></Step4>
            </tab-content>
            <tab-content :title="$t('register.complete')">
              <Step5
                @complete_step_5="CompleteStep5"
                :email="data.email"
                :password="data.password"
              ></Step5>
            </tab-content>
          </form-wizard>
        </div>
      </div>
    </div>
  </LargeLayout>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import LargeLayout from "../../../layout/main/LargeLayout.vue";
import Step1 from "../../user/register/step1.vue";
import Step2 from "../../user/register/step2.vue";
import Step3 from "../../user/register/step3.vue";
import Step4 from "../../user/register/step4.vue";
import Step5 from "../../user/register/step5.vue";
import axiosConfig from "../../../libs/axiosConfig";

export default {
  name: "register",
  data() {
    return {
      current_step: 1,
      title: this.$t("register.step1.title1"),
      titles: [
        this.$t("register.step1.title1"),
        this.$t("register.step2.title2"),
        this.$t("register.step3.title3"),
        this.$t("register.step4.title4"),
        this.$t("register.step5.title5"),
      ],
      data: {
        email: "",
        code: "",
        password: "",
        password_confirm: "",
        gender: "",
        name: "",
        family_name: "",
        given_name: "",
      },
      error_step_1: null,
      error_step_2: null,
      error_step_3: null,
      message: {
        created: {
          type: "success",
          title: "Register",
          text: "Register new account successfully!",
        },
        error: {
          type: "danger",
          title: "Error",
          text: "Something went wrong!",
        },
      },
    };
  },
  methods: {
    makeToast(option) {
      this.$bvToast.toast(this.message[option].text, {
        title: this.message[option].title,
        variant: this.message[option].type,
        solid: true,
        toaster: "b-toaster-top-center",
        autoHideDelay: 3000,
      });
    },
    NextStep1(email, error) {
      this.data.email = email;
      this.error_step_1 = error;
      axiosConfig
        .post("/user/registration/step-1", {
          email: email,
        })
        .then((res) => {
          this.$refs.form.nextTab();
        })
        .catch((error) => {
          this.error_step_1 = error.response.data.detail;
        });
    },
    NextStep2(code, email, error) {
      this.data.code = code;
      this.error_step_2 = error;
      axiosConfig
        .post("/user/registration/step-2", {
          code: code,
          email: email,
        })
        .then((res) => {
          // console.log(this.data.email, this.data.code);
          this.$refs.form.nextTab();
        })
        .catch((error) => {
          console.log(error.response.data.message);
          this.error_step_2 = error.response.data.detail;
        });
    },
    NextStep3(
      password,
      password_confirm,
      gender,
      name,
      family_name,
      given_name
    ) {
      (this.data.password = password),
        (this.data.password_confirm = password_confirm),
        (this.data.gender = gender),
        (this.data.name = name),
        (this.data.family_name = family_name),
        (this.data.given_name = given_name);

      if (password == password_confirm) this.$refs.form.nextTab();
      else this.error_step_3 = "Password and Confirm Password must be same";
    },
    PrevStep4() {
      this.$refs.form.prevTab();
    },
    NextStep4() {
      const user_info = {
        email: this.data.email,
        password: this.data.password,
        gender: this.data.gender,
        name: this.data.name,
        given_name: this.data.given_name,
        family_name: this.data.family_name,
        otp: this.data.code,
      };
      // console.log(user_info);
      axiosConfig
        .post("/user/registration/step-4", user_info)
        .then((response) => {
          this.$refs.form.nextTab();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    CompleteStep5() {
      this.$router.push("/login");
    },

    changeTitle(prev, next) {
      this.title = this.titles[next];
    },
  },
  components: {
    FormWizard,
    TabContent,
    LargeLayout,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
  },
};
</script>

<style lang="scss">
.wizard-icon {
  font-style: normal;
}
.wizard-tab-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.vue-form-wizard {
  .wizard-header {
    border-bottom: 3px solid var(--success);
    margin-bottom: 20px;
    .wizard-title {
      color: var(--success);
      text-transform: uppercase;
      font-weight: bold;
      font-size: 2.5rem;
    }
  }
  .wizard-navigation {
    .wizard-progress-with-circle {
      display: none;
    }

    .wizardSteps {
      li {
        display: flex;
        &::after {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          font-size: 30px;
          content: "\f0da";
          padding-bottom: 10px;
          color: var(--blur-green);
        }
        &:last-child::after {
          content: none;
        }
        a {
          .wizard-icon-circle,
          .md {
            width: 40px;
            height: 40px;
            border: 2px solid var(--blur-green) !important;
          }
          .wizard-icon-container {
            background-color: var(--success) !important ;
          }
        }
      }
      .active {
        a {
          .stepTitle {
            color: var(--success) !important;
            font-weight: bold;
          }
          .wizard-icon-circle {
            background-color: var(--success) !important;
            width: 45px;
            height: 45px;
            border-color: var(--success) !important;
            .wizard-icon {
              color: white;
            }
          }
        }
      }
      .stepTitle {
        color: var(--blur-green);
        font-weight: bold;
        font-size: 14px;
      }
      .wizard-icon-circle,
      .md,
      .checked {
        .wizard-icon {
          color: var(--blur-green);
        }
      }
    }
  }
}
.register {
  padding: 0 !important;
}
@media screen and (max-width: 992px) {
  .wizard-nav {
    display: none !important;
  }
  .wizard-title {
    font-size: 22px !important;
  }
  .wizard-header {
    margin-bottom: 0px !important;
  }
  .wizard-tab-content {
    padding-top: 0px !important;
  }
}
</style>
