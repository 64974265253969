<template>
  <div class="row step-3">
    <div class="card" style="border-radius: 0px">
      <div class="card-header text-white bg-success text-center h4">
        {{ $t("register.step3.title3") }}
      </div>
      <div class="card-body">
        <p class="card-body-desc h5">
          {{ $t("register.step3.subtitle") }}
        </p>

        <div class="row row-email border-0">
          <div class="col-12 col-lg-4 col-email">
            <div>
              <p class="mb-0">{{ $t("register.step3.email") }}</p>
            </div>
          </div>
          <div class="col-12 col-lg-8 col-email-8">
            <div>
              <p>{{ email }}</p>
            </div>
          </div>
        </div>
        <div class="row row-password">
          <div class="col-12 col-lg-4 col-password">
            <div>
              <p class="icons">
                <i class="fas fa-unlock-alt fa-2x"></i>
                <span>{{ $t("register.step3.password") }}</span>
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-8 col-password-8">
            <div>
              <b-form-input
                :placeholder="$t('register.step3.password-placeholder')"
                required
                class="border-0 password"
                v-model="password"
                type="password"
                @input="hideErr"
              ></b-form-input>
              <p class="d-none d-lg-block">
                {{ $t("register.step3.recommend") }}
              </p>
              <p
                class="error-password"
                v-if="password.length && !$v.password.required"
              >
                {{ $t("register.step3.password is required") }}
              </p>
              <p class="error-password" v-if="!$v.password.minLength">
                {{ $t("register.step3.Password must have at least") }}
                {{ $v.password.$params.minLength.min }}
                {{ $t("register.step3.letters") }}
              </p>

              <b-form-input
                :placeholder="$t('register.step3.enter again')"
                required
                class="d-none d-lg-block password-confirm"
                v-model="password_confirm"
                type="password"
                @input="hideErr"
              ></b-form-input>
              <div
                class="error-confirm-password d-none d-lg-block"
                v-if="!$v.password_confirm.sameAsPassword"
              >
                {{ $t("register.step3.identical") }}
              </div>
            </div>
          </div>
        </div>
        <div class="row d-block d-lg-none bg-bg p-0">
          <p class="mb-0 h5">{{ $t("register.step3.recommend") }}</p>
        </div>
        <div class="row row-password-confirm">
          <div class="col-12 d-none d-block d-lg-none bg-bg p-0">
            <b-form-input
              :placeholder="$t('register.step3.enter again')"
              required
              v-model="password_confirm"
              type="password"
              @input="hideErr"
            ></b-form-input>
            <div
              class="error-confirm-password"
              v-if="!$v.password_confirm.sameAsPassword"
            >
              {{ $t("register.step3.identical") }}
            </div>
          </div>
        </div>

        <div class="row row-info">
          <div class="col-12 col-lg-4 col-info">
            <div>
              <p class="icons">
                <i class="fas fa-user fa-2x"></i>
                <span>{{ $t("register.step3.user info") }}</span>
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-8 col-info-8">
            <div class="col-8-1">
              <div class="col-12 col-lg-6 p-0">
                <div class="row info">
                  <div class="col-2 col-lg-3 p-0">
                    {{ $t("register.step3.gender") }}
                  </div>
                  <div class="col-10 p-0 col-lg-9">
                    <b-form-input
                      :placeholder="$t('register.step3.gender-example')"
                      required
                      v-model="gender"
                      @input="hideErr"
                    ></b-form-input>
                    <p
                      class="error-user-info"
                      v-if="gender.length && !$v.gender.required"
                    >
                      {{ $t("register.step3.Gender is required") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 p-0">
                <div class="row info">
                  <div class="col-2 p-0 col-lg-3">
                    {{ $t("register.step3.name") }}
                  </div>
                  <div class="col-10 p-0 col-lg-9">
                    <b-form-input
                      :placeholder="$t('register.step3.name-example')"
                      required
                      v-model="name"
                      @input="hideErr"
                    ></b-form-input>
                    <p
                      class="error-user-info"
                      v-if="name.length && !$v.name.required"
                    >
                      {{ $t("register.step3.Name is required") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-8-2">
              <div class="col-12 col-lg-6 p-0">
                <div class="row info">
                  <div class="col-2 p-0 col-lg-3" style="white-space: nowrap">
                    {{ $t("register.step3.say") }}
                  </div>
                  <div class="col-10 p-0 col-lg-9">
                    <b-form-input
                      :placeholder="$t('register.step3.say-example')"
                      required
                      v-model="family_name"
                      @input="hideErr"
                    ></b-form-input>
                    <p
                      class="error-user-info"
                      v-if="family_name.length && !$v.family_name.required"
                    >
                      {{ $t("register.step3.Family name is required") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 p-0">
                <div class="row info">
                  <div class="col-2 p-0 col-lg-3" style="white-space: nowrap">
                    {{ $t("register.step3.may") }}
                  </div>
                  <div class="col-10 p-0 col-lg-9">
                    <b-form-input
                      :placeholder="$t('register.step3.may-example')"
                      required
                      v-model="given_name"
                      @input="hideErr"
                    ></b-form-input>
                    <p
                      class="error-user-info"
                      v-if="given_name.length && !$v.given_name.required"
                    >
                      {{ $t("register.step3.Given name is required") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p v-if="error" class="step-3_error">
          {{ $t(`error.${error}`) }}
        </p>
        <p class="p1 h5">
          {{ $t("register.step3.confirm") }}
        </p>
        <div class="text-center">
          <button type="button" class="btn btn-next text-nowrap" @click="submit_step_3">
            {{ $t("register.step3.button") }}
          </button>
        </div>
        <p class="p2 h5 pb-3">{{ $t("register.step3.privacy and term") }}</p>
        <ul
          class="pt-3 d-flex flex-column flex-lg-row justify-content-lg-between"
          style="padding-left: 15px"
        >
          <li>
            <a href="#" @click="showPolicy">
              {{ $t("register.step1.policy") }}
            </a>
          </li>
          <li>
            <a href="#" @click="showTerm">
              {{ $t("register.step1.ID term") }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
const { required, minLength, sameAs } = require("vuelidate/lib/validators");
export default {
  name: "step-3",
  props: ["email", "error"],
  data() {
    return {
      password: "",
      password_confirm: "",
      gender: "",
      name: "",
      family_name: "",
      given_name: "",
    };
  },
  mixins: [validationMixin],
  validations: {
    password: {
      required,
      minLength: minLength(6),
    },
    password_confirm: {
      sameAsPassword: sameAs("password"),
    },
    gender: {
      required,
    },
    name: {
      required,
    },
    family_name: {
      required,
    },
    given_name: {
      required,
    },
  },
  methods: {
    showTerm() {
      window.open("policies/term.pdf", "_blank", "fullscreen=yes");
      return false;
    },
    showPolicy() {
      window.open("policies/policy.pdf", "_blank", "fullscreen=yes");
      return false;
    },
    submit_step_3() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error = "please_complete_all_fields_correctly";
        return;
      }
      this.$emit(
        "next_step_3",
        this.password,
        this.password_confirm,
        this.gender,
        this.name,
        this.family_name,
        this.given_name,
        this.error
      );
    },
    hideErr() {
      this.error = "";
    },
  },
};
</script>

<style lang="scss">
.step-3 {
  margin: 0 !important;
  .card {
    padding: 0;
    .card-header {
      padding: 10px;
      text-transform: uppercase;
      font-weight: bold;
    }
    .card-body {
      padding-left: 40px;
      padding-right: 40px;
      background-color: var(--bg);
      .card-body-desc {
        text-align: center;
      }
      .row {
        margin: 0px 10px;
        display: flex;
        align-items: center;
        background-color: var(--regis-bg-blur-gray);

        .col-4 {
          text-align: center;
          .fas {
            font-size: 30px;
            margin-right: 10px;
          }
        }
        .col-8 {
          border-left: 1px solid;
          background-color: white !important;
          .form-control {
            border: 1px solid;
            border-radius: 0px;
          }
          .col-8-1,
          .col-8-2 {
            display: flex;
            margin-bottom: 16px;
          }
          .col-6 {
            display: flex;
            align-items: center;
            .col-3,
            .col-9 {
              padding: 0;
            }
          }
        }
        .col-4,
        .col-8 {
          padding-top: 16px;
        }
      }
      .row-email {
        border: 1px solid;
        border: 1px solid black !important;
      }
      .row-info,
      .row-password {
        border-left: 1px solid;
        border-right: 1px solid;
        border-bottom: 1px solid;
      }
      .btn-next {
        width: 30%;
        color: white;
        background-color: var(--regis-btn-orange);
        border-color: var(--regis-btn-orange);
        margin-bottom: 30px;
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 5px;
        text-shadow: -1px -1px 1px var(--regis-text-shadow);
        box-shadow: inset 3px 6px 6px -3px var(--regis-box-shadow-white),
          inset -3px -7px 6px -3px var(--regis-box-shadow-orange);
      }
      .step-3_error {
        color: red;
        text-align: center;
        margin-bottom: 0px;
        margin-top: 0.75rem;
      }
      .p1 {
        padding: 15px;
      }
      .p2 {
        border-bottom: 3px solid var(--success);
        margin-bottom: 0;
        font-weight: bold;
      }
      .p3 {
        display: flex;
        justify-content: space-between;
        padding-left: 15px;
      }
    }
  }
  .col-email,
  .col-password,
  .col-info {
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .col-password-8,
  .col-email-8,
  .col-info-8 {
    background: white;
    border-left: 1px solid black;
    font-size: 1.25rem;
    padding: 1.25rem;
  }
  .col-password-8 {
    input {
      border: 1px solid black !important;
      border-radius: 0px;
    }
    .error-password,
    .error-confirm-password {
      color: red;
      text-align: left;
    }
  }
  .icons {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  ::placeholder {
    font-size: 1.25rem;
  }

  .info {
    background: white !important;
    input {
      border: 1px solid black;
    }
  }
  .col-email-8 {
    padding-top: 16px;
    p {
      margin: 0;
    }
  }
  .col-info-8 {
    input {
      border-radius: 0px;
    }
  }
}
@media screen and (min-width: 992px) {
  .col-info-8 {
    padding-top: 10px;
    padding-bottom: 10px;
    .step3-gender,
    .step3-name {
      white-space: nowrap !important;
    }
  }
  .col-8-1,
  .col-8-2 {
    display: flex !important;
  }
  .col-8-1 {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 992px) {
  .step-3 {
    padding: 10px 20px !important;
    .btn-next {
      margin-top: 0 !important;
      width: 100% !important;
    }
    .card-header {
        padding: 10px 20px;
        font-size: 18px;
      }
    .card-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    .col-email,
    .col-password,
    .col-info {
      background: var(--success);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.25rem;
      color: white;
      padding: 5px;
      p {
        margin: 0;
      }
    }

    .col-info {
      border: 1px solid black;
    }
    .row-info {
      border-left: 0 !important;
      border-right: 0 !important;
      border-bottom: 0 !important;
    }
    .info {
      background: var(--bg) !important;
      margin: 10px 0 0 0 !important;
      input {
        border-radius: 0 !important;
        border: 1px solid black !important;
      }
    }
    .col-info-8 {
      background: var(--bg);
    }
    .col-password-8,
    .col-email-8,
    .col-info-8 {
      padding: 0;
      border-left: 0;
    }
    .row-email {
      border: 0px !important;
      .col-email-8 {
        text-align: center !important;
        padding: 5px;
      }
    }
    .row-password-confirm {
      .col-12 {
        input {
          border-radius: 0 !important;
          border: 1px solid black !important;
        }
        input::placeholder {
            font-size: 20px !important;
          }
      }
    }
    .card {
      .card-body {
        .row-email {
          border: 0px !important;
          .col-email {
            border: 1px solid black !important;
          }
          .col-email-8 {
            border: 1px solid black !important;
            border-top: 0 !important;
          }
        }
        .row-password {
          border: 0px !important;
          .col-password {
            border: 1px solid black !important;
            border-bottom: 0 !important;
          }
        }
        .row-password-confirm {
          input {
            border: 1px solid black !important;
            border-radius: 0px !important;
          }
        }
      }
    }
  }
  .error-confirm-password {
    color: red;
  }
}
</style>
