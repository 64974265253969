<template>
  <div class="step-2">
    <div class="card" style="border-radius: 0px">
      <div class="card-header text-white bg-success text-center h4">
        {{ $t("register.step2.subtitle") }}
      </div>
      <div class="card-body">
        <p class="card-body-desc d-none d-lg-block">
          {{ $t("register.step2.enter") }}
        </p>

        <div class="row row-email">
          <div class="col-12 col-lg-4 col-email h5 mb-0">
            <div>
              <p class="d-flex justify-content-center mb-0 py-1 mb-0">
                {{ $t("register.step2.email") }}
              </p>
            </div>
          </div>
          <div class="email col-12 col-lg-8 h5 mb-0 bg-white py-1 text-center">
            <div>
              <b-form-input
                v-model="email"
                disabled
                class="inputed_email bg-white pl-0"
              ></b-form-input>
            </div>
          </div>
        </div>
        <div class="row row-code">
          <div class="col-12 col-lg-4 col-code h5 mb-0">
            <p class="d-flex key py-1 mb-0">
              <i class="fas fa-key fa-2x"></i>
              <span>{{ $t("register.step2.key") }}</span>
            </p>
          </div>
          <div class="col-12 col-lg-8 h5 py-1 mb-0 password bg-white">
            <div>
              <b-form-input
                :placeholder="$t('register.step2.password')"
                required
                v-model="code"
                class="enter-key"
                @input="hideErr"
              ></b-form-input>

              <p class="d-none d-lg-block mt-2 mb-0 pb-3">
                {{ $t("register.step2.time") }}
              </p>
            </div>
          </div>
        </div>
        <p
          v-if="error"
          class="step-2_error text-center mb-0"
          style="color: red"
        >
          {{ $t(`error.${error}`) }}
        </p>
        <div class="row bg-bg">
          <p class="d-lg-none mb-0">{{ $t("register.step2.time") }}</p>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-next" @click="submit_step_2">
            {{ $t("register.step2.next") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "step-2",
  props: ["email", "error"],
  data() {
    return {
      code: "",
    };
  },
  methods: {
    submit_step_2() {
      this.$emit("next_step_2", this.code, this.email, this.error);
    },
    hideErr() {
      this.error = "";
    },
  },
};
</script>

<style lang="scss">
.step-2 {
  .card {
    padding: 0;
    .card-header {
      padding: 10px;
      text-transform: uppercase;
      font-weight: bold;
    }
    .card-body {
      font-size: 1.25rem !important;
      background-color: var(--bg);
      .card-body-desc {
        text-align: center;
      }
      .row {
        margin: 0px 30px;
        display: flex;
        align-items: center;
        background-color: var(--regis-bg-blur-gray);
        .col-4 {
          text-align: center;
          .fa-key {
            font-size: 30px;
            margin-right: 10px;
          }
        }
        .col-8 {
          border-left: 1px solid;
          background-color: white !important;
          .form-control {
            border: 1px solid;
            border-radius: 0px;
            width: 50%;
          }
        }
        .col-4,
        .col-8 {
          padding-top: 16px;
        }
      }
      .row-email {
        border: 1px solid;
        ::placeholder {
          font-size: 1.3rem;
        }
        .inputed_email {
          font-size: 1.25rem;
        }
      }
      .row-code {
        border-left: 1px solid;
        border-right: 1px solid;
        border-bottom: 1px solid;
        ::placeholder {
          font-size: 1.3rem;
        }
        .enter-key {
          width: 50%;
          margin-top: 10px;
          border-radius: 0px;
          text-align: center;
          @media screen and (max-width: 992px) {
            margin-top: 0
          }
        }
      }
      .btn-next {
        color: white;
        background-color: var(--regis-btn-orange);
        border-color: var(--regis-btn-orange);
        margin-bottom: 30px;
        margin-top: 10px;
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 5px;
        text-shadow: -1px -1px 1px var(--regis-text-shadow);
        box-shadow: inset 3px 6px 6px -3px var(--regis-box-shadow-white),
          inset -3px -7px 6px -3px var(--regis-box-shadow-orange);
        width: 30%;
      }
    }
  }
  .password,
  .email {
    border-left: 1px solid;
  }
  .email {
    input {
      border: none;
    }
  }
  .password {
    input {
      border: 1px solid black;
    }
  }
  .key {
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 992px) {
  .step-2 {
    padding: 10px 20px !important;
    .card {
      .card-header {
          padding: 10px 20px;
          font-size: 18px;
        }
      .card-body {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding-top: 16px;
        .row-email,
        .row-code {
          border: 0px !important;
          margin: 0px 0px !important;
          .col-12 {
            padding: 0px !important;
            border: 1px solid black !important;
            border-top: 0px !important ;
          }
          .col-lg-4 {
            margin-top: 0 !important;
          }
          .col-email,
          .col-code {
            border-top: 1px solid black !important;
          }
        }
      }
      .password {
        input {
          border: none;
        }
      }
      .col-code,
      .col-email {
        background: var(--success);
        color: white;
        text-align: center;
      }
      .row-code {
        .enter-key {
          width: 100% !important;
        }
      }
      .btn-next {
        margin-top: 0 !important;
        width: 60% !important;
        margin-bottom: 0 !important;
      }

      .inputed_email {
        background-color: white !important;
        text-align: center !important;
      }
    }
  }
}
</style>
<style lang="scss" scoped>

</style>
