<template>
  <div class="step-4">
    <div class="card" style="border-radius: 0px">
      <div class="card-header text-white bg-success text-center h4">
        {{ $t("register.step4.title4") }}
      </div>
      <div class="card-body">
        <div class="row row-email">
          <div class="col-12 col-lg-4 col-email">
            <div>
              <p class="h5">{{ $t("register.step4.email") }}</p>
            </div>
          </div>
          <div class="col-12 col-lg-8 col-email-8">
            <div>
              <p>{{ email }}</p>
            </div>
          </div>
        </div>
        <div class="row row-password">
          <div class="col-12 col-lg-4 col-password h5">
            <div>
              <p class="mb-0">
                <i class="fas fa-unlock-alt fa-2x d-lg-none"></i>
                {{ $t("register.step4.password") }}
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-8 col-password-8">
            <div>
              <div>
                <b-form-input
                  v-model="password"
                  type="password"
                  disabled
                  class="border-0 bg-white"
                ></b-form-input>
              </div>
              <p style="color: red" class="h5 d-none d-lg-block">
                {{ $t("register.step4.not display") }}
              </p>
            </div>
          </div>
        </div>
        <div class="row bg-bg">
          <p style="color: red; font-size: 18px" class="d-lg-none">
            {{ $t("register.step4.not display") }}
          </p>
        </div>
        <div class="row row-name">
          <div class="col-12 col-lg-4 col-name h5">
            <div>
              <p class="mb-0">
                <i class="fas fa-user fa-2x d-lg-none"></i>
                {{ $t("register.step4.name") }}
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-8 col-name-8">
            <div class="col-name-8-1">
              {{ family_name }}
            </div>
            <div class="col-name-8-2">
              {{ given_name }}
            </div>
          </div>
        </div>

        <div
          class="
            card-body__button
            d-flex
            flex-lg-row flex-column
            justify-content-center
            align-items-center
          "
        >
          <button
            type="button"
            class="btn btn-return mx-4"
            @click="$emit('prev-step-4')"
          >
            {{ $t("register.step4.return") }}
          </button>
          <button
            type="button"
            class="btn btn-next"
            @click="$emit('next-step-4')"
          >
            {{ $t("register.step4.next") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "step-4",
  props: ["email", "password", "family_name", "given_name"],
};
</script>

<style lang="scss">
.step-4 {
  font-size: 1.25rem;
  .col-email-8,
  .col-password-8,
  .col-name-8 {
    padding: 1.25rem;
    border-left: 1px solid black;
    background: white;
  }
  hr {
    border-top: 1px solid black;
  }
  .card {
    padding: 0;
    .card-header {
      padding: 10px;
      text-transform: uppercase;
      font-weight: bold;
    }
    .card-body {
      background-color: var(--bg);
      .card-body-desc {
        text-align: center;
      }
      .row {
        margin: 0px 30px;
        display: flex;
        align-items: center;
        background-color: var(--regis-bg-blur-gray);

        .col-4 {
          text-align: center;
          .fa-key {
            font-size: 30px;
            margin-right: 10px;
          }
        }
        .col-8 {
          border-left: 1px solid;
          background-color: white !important;
          .form-control {
            border: 1px solid;
            border-radius: 0px;
            width: 50%;
          }
        }
        .col-4,
        .col-8 {
          padding-top: 16px;
        }
      }
      .row-email {
        border: 1px solid;
        p {
          margin: 0;
        }
      }
      .row-password {
        .col-password-8 {
          input {
            padding: 0;
          }
        }
      }
      .row-password,
      .row-name {
        border-left: 1px solid;
        border-right: 1px solid;
        border-bottom: 1px solid;
      }
      .row-name {
        .col-name-8 {
          padding: 0px !important;
          .col-name-8-1,
          .col-name-8-2 {
            padding: 20px;
          }
          .col-name-8-1 {
            border-bottom: 1px solid black;
          }
        }
      }
      ::placeholder {
        font-size: 1.25rem;
      }
      .card-body__button {
        display: flex;
        justify-content: space-around;
        .btn-next {
          color: white;
          background-color: var(--regis-btn-orange);
          border-color: var(--regis-btn-orange);
          text-transform: uppercase;
          font-weight: bold;
          margin-top: 30px;
          margin-bottom: 30px;
          width: 30%;
          border-radius: 5px;
          text-shadow: -1px -1px 1px var(--regis-text-shadow);
          box-shadow: inset 3px 6px 6px -3px var(--regis-box-shadow-white),
            inset -3px -7px 6px -3px var(--regis-box-shadow-orange);
        }
        .btn-return {
          color: var(--regis-btn-orange);
          background-color: white;
          border-color: var(--regis-btn-orange);
          text-transform: uppercase;
          font-weight: bold;
          margin-top: 30px;
          margin-bottom: 30px;
          width: 30%;
          border-radius: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .step-4 {
    padding: 10px 20px !important;
    .col-email-8,
    .col-password-8 {
      border-left: 0px;
      padding: 5px 20px !important;
    }
    .col-name-8-1, .col-name-8-2{
      padding: 5px 20px !important;
    }
    .card-header {
        padding: 10px 20px;
        font-size: 18px;
      }
    .card-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .row-email {
        .col-email-8 {
          text-align: center !important;
        }
      }
    }
    .col-email,
    .col-password,
    .col-name {
      background: var(--success);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      padding: 10px;
    }
    .col-password,
    .col-name,
    .col-email {
      border: 1px solid black;
    }

    .col-email-8,
    .col-password-8,
    .col-name-8 {
      border: 1px solid black;
      border-top: 0px !important;
    }

    .btn-next,
    .btn-return {
      margin-top: 0 !important;
      width: 60% !important;
    }

    .row-email,
    .row-password,
    .row-name {
      border: 0px !important;
    }
  }
}
</style>
