<template>
  <div class="step-5">
    <div class="card text-center">
      <div class="card-header text-white bg-success">
        {{ $t("register.step5.title5") }}
      </div>
      <div class="card-body">
        <img
          alt="wuDN"
          src="../../../assets/header/logo.png"
          style="width: 50%"
          class="logo"
        />

        <p class="h6">{{ $t("register.step5.logo") }}</p>
        <p class="h6">{{ $t("register.step5.message") }}</p>
        <p class="h6">{{ $t("register.step5.thanks") }}</p>
        <button
          type="button"
          class="btn btn-complete mt-3"
          @click="$emit('complete_step_5')"
        >
          {{ $t("register.step5.button") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "step-5",
  props: ["email", "password"],
};
</script>

<style lang="scss">
.step-5 {
  .card {
    padding: 0;
    .card-header {
      padding: 10px;
      text-transform: uppercase;
      font-weight: bold;
    }
    .card-body {
      background-color: var(--bg);
      .logo {
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 0px;
      }
      .btn-complete {
        color: white;
        background-color: var(--regis-btn-orange);
        border-color: var(--regis-btn-orange);
        margin-bottom: 30px;
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 5px;
        text-shadow: -1px -1px 1px var(--regis-text-shadow);
        box-shadow: inset 3px 6px 6px -3px var(--regis-box-shadow-white),
          inset -3px -7px 6px -3px var(--regis-box-shadow-orange);
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .step-5 {
    padding: 10px 20px !important;
    .card-body {
      padding: 20px 0; 
    }
    .card-header {
      padding: 10px 20px;
      font-size: 18px;
    }
    .btn-complete {
      width: 60% !important;
    }
  }
}
</style>
