<template>
  <div class="row step-1">
    <p class="step-1__desc mx-auto h5 p-2">
      {{ $t("register.step1.text") }}
    </p>
    <div class="step-1__form">
      <div class="card" style="border-radius: 0px">
        <div class="card-header text-white bg-success text-center h4" >
          {{ $t("register.step1.please") }}
        </div>
        <div class="card-body">
          <div class="step-1__form-email d-lg-flex flex-lg-row">
            <div class="d-flex justify-content-center align-items-center">
              <i class="far fa-envelope mail-icon"></i>
              <span class="p1 text-center h5">
                {{ $t("register.step1.email address") }}
              </span>
            </div>
            <b-form-input
              v-model="email"
              type="email"
              required
              style="border-radius: 0"
              placeholder="abc@xxxxxx.co.jp"
              @input="hideErr"
            ></b-form-input>
          </div>
          <div class="step-1_error" v-if="email.length && !$v.email.required">
            {{ $t("register.step1.Email is required") }}
          </div>
          <div class="step-1_error" v-if="!$v.email.email">
            {{ $t("register.step1.Email must be valid") }}
          </div>
          <p v-if="error" class="step-1_error">
            {{ $t(`error.${error}`) }}
          </p>

          <p class="p2 step-1__text">
            {{ $t("register.step1.temporary") }}<br />
            {{ $t("register.step1.restricted") }}
          </p>
          <hr class="border-3 border-success hr" />
          <p class="p3 step-1__text">
            {{ $t("register.step1.agree") }}
          </p>
          <div class="text-center">
            <button type="button" class="btn mail-btn" @click="submit_step_1">
              {{ $t("register.step1.button") }}
            </button>
          </div>
          <p class="p4 step-1__text">{{ $t("register.step1.privacy and term") }}</p>
          <hr class="border-3 border-success hr" />
          <ul class="p5 d-flex flex-column flex-lg-row">
            <li>
              <a href="#" @click="showPolicy">
                {{ $t("register.step1.policy") }}
              </a>
            </li>
            <li>
              <a href="#" @click="showTerm">
                {{ $t("register.step1.ID term") }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
const { required, email } = require("vuelidate/lib/validators");
export default {
  name: "step-1",
  props: ["error"],
  data() {
    return {
      email: "",
    };
  },
  mixins: [validationMixin],
  validations: {
    email: {
      email,
      required,
    },
  },

  methods: {
    submit_step_1() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error = "the_email_field_is_required!";
        return;
      }
      this.$emit("next_step_1", this.email, this.error);
    },
    hideErr() {
      this.error = "";
    },
    showTerm() {
      window.open("policies/term.pdf", "_blank", "fullscreen=yes");
      return false;
    },
    showPolicy() {
      window.open("policies/policy.pdf", "_blank", "fullscreen=yes");
      return false;
    },
  },
};
</script>

<style lang="scss">
.step-1 {
  margin: 0 !important;
  .step-1__desc {
    border: 2px solid var(--success);
    border-radius: 5px;
    color: var(--success);
  }
  .step-1__form {
    padding: 0;
    .card-header {
      padding: 10px;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 24px;
      @media screen and (max-width: 992px) {
        padding: 10px 0;
        font-size: 22px;
        letter-spacing: 0.5px
      }
    }
    .card-body {
      background-color: var(--bg);
      padding: 40px;
      .step-1__form-email {
        align-items: center;
        .mail-icon {
          font-size: 40px;
        }
        .p1 {
          margin: 10px;
          white-space: nowrap;
        }
        input {
          flex-shrink: 1;
          font-size: 1.25rem;
          border: 1px solid black;
        }
      }
      .step-1_error {
        color: red;
        text-align: right;
      }
      .p2,
      .p3 {
        padding: 15px;
      }
      .mail-btn {
        color: white;
        background-color: var(--regis-btn-orange);
        border-color: var(--regis-btn-orange);
        margin-bottom: 30px;
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 5px;
        text-shadow: -1px -1px 1px var(--regis-text-shadow);
        box-shadow: inset 3px 6px 6px -3px var(--regis-box-shadow-white),
          inset -3px -7px 6px -3px var(--regis-box-shadow-orange);
        padding: 10px 20px;
        font-size: 1.5rem;
      }
      .p4 {
        margin-bottom: 0;
        font-weight: bold;
      }
      .p5 {
        display: flex;
        justify-content: space-between;
        padding-left: 15px;
      }
    }
  }
}
.wizard-card-footer {
  display: none;
}
::placeholder {
  font-size: 1.25rem;
}
@media screen and (max-width: 992px) {
  .step-1 {
    padding: 10px 20px;
    .step-1__form {
      .card {
        .card-body {
          padding: 10px !important;
          .step-1__form-email {
            .p1 {
              margin: 0;
            }
          }
          .p2,
          .p3 {
            padding-left: 0px;
            padding-right: 0px;
          }
          .hr {
            margin: 0 -10px;
          }
          .mail-btn {
            padding: 0px 10px !important;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
  .step-1__text {
    font-size: 20px;
    @media screen and (max-width: 992px) {
      font-size: 19px;
    }
  }
</style>
